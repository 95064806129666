import { Box, Skeleton } from "@chakra-ui/react";
export default function HomePageLoading(): JSX.Element {
  return (
    <Box
      padding="6"
      bg="#eee"
    >
      <Skeleton height="100px" />
      <br />
      <Skeleton
        height="300px"
        startColor="gray.700"
        endColor="gray.50"
      />
      <br />
      <Skeleton
        height="200px"
        startColor="black"
        endColor="white"
      />
    </Box>
  );
}
