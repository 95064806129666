import React, { useRef } from "react";
import * as S from "./styles";
import Color from "color";
import {
  IconButton,
  Link,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerFooter,
  useDisclosure,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { SocialIcons } from "../../../components/SocialIcons/SocialIcons";
import { NavLink } from "react-router-dom";
import { OCVSearchButton } from "../../../components/AlgoliaSearch/OCVSearchButton";

export const MobileNav = ({
  manifestData,
}: Record<string, any>): JSX.Element => {
  const images = manifestData?.["stylesheet"]["images"];
  const featuresList = manifestData?.["features"];
  const anchorList = manifestData?.["views"];
  const config: any = manifestData?.views?.navbar?.config;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement | any>();
  //scrolling to offset for navBar height

  const handleMoveScreenMobile = () => {
    const moveScreenMobile = () => {
      scrollTo({ top: -300, behavior: "smooth" });
    };
    addEventListener("hashchange", moveScreenMobile);
    return () => {
      removeEventListener("hashchange", moveScreenMobile);
    };
  };
  const primaryColor = manifestData?.["stylesheet"]["colors"]["primary"];
  const tertiaryColor = manifestData?.["stylesheet"]["colors"]["tertiary"];

  const color1 = Color(primaryColor).lightness(40);
  const subColor = color1.toString();
  const color2 = color1.lightness(30);
  const nestedColor = color2.toString();

  if (!config) return <p>Loading..</p>;
  return (
    config && (
      <S.Nav
        id="mobileBar"
        primary={primaryColor}
        tertiary={tertiaryColor}
      >
        <Link
          id="homeImgLink"
          cursor="pointer"
          fontFamily="Roboto"
          fontWeight="500"
          textDecoration="none"
          z-index="-1"
          top="10px"
          position="fixed"
          href="/"
        >
          <Image
            src={images?.["titleImage"]?.["url"]}
            alt={images?.["titleImage"]?.["altText"]}
            title="Go to Homepage"
            maxHeight={"98px"}
            maxWidth="80%"
          />
        </Link>
        <IconButton
          z-index="-1"
          position="fixed"
          top="20px"
          right="10px"
          cursor="ponter"
          backgroundColor={primaryColor}
          color="#fff"
          size="lg"
          aria-label="mobile navigation button"
          onClick={() => onOpen()}
          ref={btnRef}
          fontSize="24px"
          icon={<HamburgerIcon fontSize="24px" />}
        />
        <nav>
          <Drawer
            id="mobileNavFlyout"
            size="md"
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay id="mobileDrawerOverlay" />

            <DrawerContent aria-label="mobileNavigation">
              <DrawerCloseButton
                id="drawerCloseButton"
                color="#fff"
              />
              <DrawerHeader
                id="DrawerHeader"
                backgroundColor={primaryColor}
              />
              <DrawerBody
                id="mobileNavigation"
                color="#fff"
                backgroundColor={primaryColor}
              >
                <Link
                  key="homeLink"
                  id="homeLink"
                  pl="15px"
                  fontFamily="Roboto"
                  fontWeight="800"
                  color="#fff"
                  textDecorationLine="none"
                  href="/"
                >
                  Home
                </Link>
                <Accordion
                  id="mobileAccordion"
                  width="100%"
                  pl="0"
                  pr="0"
                  allowMultiple
                  backgroundColor={primaryColor}
                  textColor="#fff"
                  borderTopWidth="1px"
                >
                  {config.anchors?.map((anchor: any) => (
                    <AccordionItem
                      key={anchor + `-singleItem`}
                      id={anchor + `-singleItem`}
                      borderWidth="0"
                      padding="8px 16px"
                      fontWeight="700"
                      onClick={handleMoveScreenMobile()}
                    >
                      {/* single item */}
                      <a
                        key={anchorList[anchor]?.title + `-singleIn`}
                        id={anchorList[anchor]?.title + `-singleIn`}
                        href={`/#` + anchor}
                        style={{ width: "100%" }}
                        onClick={onClose}
                      >
                        <Box width="inherit">{anchorList[anchor].title}</Box>
                      </a>
                    </AccordionItem>
                  ))}
                  {config.items?.map((item: any) =>
                    featuresList[item].type === "submenu" ? (
                      <AccordionItem
                        key={item + `-submenu`}
                        id={item + `-submenu`}
                        borderBottomWidth="1px"
                      >
                        <Heading>
                          <AccordionButton fontWeight="700">
                            <Box
                              as="span"
                              flex="1"
                              textAlign="left"
                            >
                              {featuresList[item].navHeader}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </Heading>
                        <AccordionPanel
                          backgroundColor={subColor}
                          onClick={onClose}
                        >
                          {featuresList[item]?.dropdown?.map(
                            (dropdownItem: any) =>
                              featuresList[dropdownItem]?.external === true ? (
                                <Link
                                  key={dropdownItem}
                                  id={dropdownItem}
                                  textDecorationLine="none"
                                  href={featuresList[dropdownItem]?.url}
                                  isExternal
                                >
                                  <Box
                                    width="inherit"
                                    p="1"
                                  >
                                    {featuresList[dropdownItem]?.title}
                                  </Box>
                                </Link>
                              ) : (
                                //internal link
                                <Link
                                  key={dropdownItem}
                                  id={dropdownItem}
                                  textDecorationLine="none"
                                  as={NavLink}
                                  to={dropdownItem}
                                  onClick={onClose}
                                >
                                  <Box
                                    width="inherit"
                                    p="1"
                                    ml="1"
                                  >
                                    {featuresList[dropdownItem]?.title}
                                  </Box>
                                </Link>
                              )
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                    ) : featuresList[item].type === "megaMenu" ? (
                      <AccordionItem
                        key={item + `-megamenu`}
                        id={item + `-megamenu`}
                        borderBottomWidth="1px"
                      >
                        <Heading>
                          <AccordionButton
                            id={featuresList[item].navHeader}
                            aria-labelledby={featuresList[item].navHeader}
                          >
                            <Box
                              as="span"
                              flex="1"
                              textAlign="left"
                              fontWeight="700"
                            >
                              {featuresList[item].navHeader}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </Heading>
                        {featuresList[item]?.dropdown?.map(
                          (dropdownItem: any) => (
                            <AccordionPanel
                              key={dropdownItem.columnHeader}
                              id={dropdownItem.columnHeader}
                              pl="0"
                              pr="0"
                              backgroundColor={subColor}
                            >
                              <AccordionItem border="0px">
                                <Heading>
                                  <AccordionButton fontWeight="700">
                                    <Box
                                      as="span"
                                      flex="1"
                                      textAlign="left"
                                      ml="3"
                                    >
                                      {dropdownItem.columnHeader}
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </Heading>

                                {dropdownItem.items?.map((item: any) =>
                                  featuresList[item]?.external === true ? (
                                    //external link
                                    <AccordionPanel
                                      key={item + `-ex`}
                                      id={item + `-ex`}
                                      pl="0"
                                      pr="0"
                                      backgroundColor={nestedColor}
                                    >
                                      <Link
                                        textDecorationLine="none"
                                        href={featuresList[item]?.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={onClose}
                                        isExternal
                                      >
                                        <Box
                                          width="inherit"
                                          p="1"
                                          ml="8"
                                        >
                                          {featuresList[item]?.title}
                                        </Box>
                                      </Link>
                                    </AccordionPanel>
                                  ) : (
                                    //internal link
                                    <AccordionPanel
                                      key={item + `-ex`}
                                      id={item + `-ex`}
                                      pl="0"
                                      pr="0"
                                      backgroundColor={nestedColor}
                                    >
                                      <Link
                                        key={item + `-in`}
                                        id={item + `-in`}
                                        textDecorationLine="none"
                                        as={NavLink}
                                        to={item}
                                        onClick={onClose}
                                      >
                                        <Box
                                          width="inherit"
                                          p="1"
                                          ml="8"
                                        >
                                          {featuresList[item]?.title}
                                        </Box>
                                      </Link>
                                    </AccordionPanel>
                                  )
                                )}
                              </AccordionItem>
                            </AccordionPanel>
                          )
                        )}
                      </AccordionItem>
                    ) : (
                      <AccordionItem
                        key={item + `-singleItem`}
                        id={item + `-singleItem`}
                        borderWidth="0"
                        padding="8px 16px"
                        fontWeight="700"
                      >
                        {/* single item */}

                        {featuresList[item]?.external === true ? (
                          // external link
                          <Link
                            key={featuresList[item]?.title + `-singleEx`}
                            id={featuresList[item]?.title + `-singleEx`}
                            textDecorationLine="none"
                            href={featuresList[item]?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={onClose}
                            isExternal
                          >
                            <Box width="inherit">
                              {featuresList[item]?.title}
                            </Box>
                          </Link>
                        ) : (
                          // internal link
                          <Link
                            key={featuresList[item]?.title + `-singleIn`}
                            id={featuresList[item]?.title + `-singleIn`}
                            textDecorationLine="none"
                            as={NavLink}
                            to={item}
                            onClick={onClose}
                          >
                            <Box width="inherit">
                              {featuresList[item].title}
                            </Box>
                          </Link>
                        )}
                      </AccordionItem>
                    )
                  )}
                </Accordion>
              </DrawerBody>
              <DrawerFooter
                data-testid={"mobileNavFooter"}
                justifyContent="space-evenly"
                backgroundColor={primaryColor}
                transition="0.25s"
                color="#f0f0f0"
                alignItems="center"
                padding="1rem"
              >
                {config?.socialMedia?.map(
                  (item: { icon: string; url: string; name: string }) => (
                    <Link
                      key={item.url}
                      id={item.url}
                      href={item.url}
                      fontFamily="Roboto"
                      fontWeight="500"
                      textDecoration="none"
                      borderTopColor="tertiaryColor"
                      backgroundColor="tertiaryColor"
                      onClick={onClose}
                    >
                      {SocialIcons(item.icon, "45px")}
                    </Link>
                  )
                )}
                {!config.hideSearch && (
                  <OCVSearchButton ocvsize={43}></OCVSearchButton>
                )}
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </nav>
      </S.Nav>
    )
  );
};
