import { ExternalLink, StyledLink, StyledAnchor } from "../Links/Links";
import * as S from "./styles";
import { Dropdown } from "./Dropdown/Dropdown";
import { SocialIcons } from "../../../components/SocialIcons/SocialIcons";
import { Image } from "@chakra-ui/react";
import styled from "styled-components";
import { OCVSearchButton } from "../../../components/AlgoliaSearch/OCVSearchButton";

export const StyledHeading = styled.h1`
  font-family: Fjalla One, sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export function DesktopNav({ manifestData }: Record<string, any>): JSX.Element {
  const images = manifestData?.["stylesheet"]["images"];
  const featuresList = manifestData?.["features"];
  const anchorList = manifestData?.["views"];
  const config: any = manifestData?.views?.navbar?.config;
  const primaryColor = manifestData?.["stylesheet"]["colors"]["primary"];
  const tertiaryColor = manifestData?.["stylesheet"]["colors"]["tertiary"];

  const colors = {
    primaryColor,
    tertiaryColor,
  };

  if (!config) return <p>Loading..</p>;
  return (
    config && (
      <S.StyledNavbar
        $primary={colors.primaryColor}
        $tertiary={colors.tertiaryColor}
      >
        <S.NavDiv>
          <StyledLink to="/">
            <Image
              src={images?.["titleImage"]?.["url"]}
              alt={images?.["titleImage"]?.["altText"]}
              title="Go to Homepage"
              maxWidth={config?.imgWidth ? config?.imgWidth : "75px"}
              verticalAlign="middle"
              display="inline"
            />
            {config?.title && (
              <StyledHeading
                dangerouslySetInnerHTML={{ __html: config?.title }}
              />
            )}
          </StyledLink>
        </S.NavDiv>
        <S.Navigation aria-labelledby="primaryMenu">
          <S.NavList
            id="primaryMenu"
            role="menu"
          >
            {config.anchors?.map((anchor: any, index: number) => (
              <S.NavListItem
                key={anchor + index}
                $primary={colors.primaryColor}
                role="menuitem"
              >
                <StyledAnchor
                  href={`/#` + anchor}
                  tabIndex={0}
                >
                  {anchorList[anchor].title}
                </StyledAnchor>
              </S.NavListItem>
            ))}
            {config.items?.map((item: any, index: number) => (
              <S.NavListItem
                $type={featuresList[item].type}
                key={item + index}
                $primary={colors.primaryColor}
                role="menuitem"
              >
                {featuresList[item].type === "submenu" ||
                featuresList[item].type === "megaMenu" ? (
                  <Dropdown
                    featuresList={featuresList}
                    item={item}
                    index={index}
                    colors={colors}
                  />
                ) : featuresList[item]?.external === true ? (
                  <ExternalLink
                    href={featuresList[item].url}
                    target="_blank"
                    rel="noopener noreferrer"
                    tabIndex={0}
                  >
                    {featuresList[item].title}
                  </ExternalLink>
                ) : (
                  <StyledLink
                    to={item}
                    tabIndex={0}
                  >
                    {featuresList[item].title}
                  </StyledLink>
                )}
              </S.NavListItem>
            ))}
          </S.NavList>
        </S.Navigation>
        <S.NavDiv>
          {config?.socialMedia?.map(
            (
              item: { icon: string; url: string; name: string },
              index: number
            ) => (
              <ExternalLink
                href={item.url}
                key={item.icon + index}
                target="_blank"
                aria-label={`Link to ${item.name}`}
              >
                {SocialIcons(item.icon, "22px")}
              </ExternalLink>
            )
          )}
          {!config.hideSearch && (
            <OCVSearchButton ocvsize={22}></OCVSearchButton>
          )}
        </S.NavDiv>
      </S.StyledNavbar>
    )
  );
}
